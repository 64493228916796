import request from '../utils/request'
import baseUrl from './baseUrl'

export const roleList = (data) => request({
  url: baseUrl + '/role/findRoleList',
  method: 'POST',
  data
})
//新增角色
export const addRole = (data) => request({
  url: baseUrl + '/role/addRole',
  method: 'POST',
  data
})
//修改角色
export const editRole = (data) => request({
  url: baseUrl + '/role/updateRole',
  method: 'POST',
  data
})
//删除角色
export const delRole = (data) => request({
  url: baseUrl + '/role/deleteRole',
  method: 'POST',
  data
})
