var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rolelist"},[_c('el-breadcrumb',{attrs:{"separator":"/"}},[_c('el-breadcrumb-item',[_vm._v("角色列表")])],1),_c('div',{staticClass:"search"},[_c('span',[_vm._v("名称:")]),_c('el-input',{attrs:{"placeholder":"请输入栏目名称"},model:{value:(_vm.roleName),callback:function ($$v) {_vm.roleName=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"roleName"}}),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.getRoleList()}}},[_vm._v("搜索")]),(
        _vm.$store.state.powerList.indexOf('system:permission:role:insert') !== -1
      )?_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.addRoleDialogVisible = true}}},[_vm._v("添加")]):_vm._e(),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("返回")])],1),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"border":"","data":_vm.tableData},on:{"selection-change":_vm.handleSelectionChange}},[_c('el-table-column',{attrs:{"align":"center","type":"selection","width":"55"}}),_c('el-table-column',{attrs:{"align":"center","prop":"roleId","label":"角色主键","width":"80"}}),_c('el-table-column',{attrs:{"align":"center","prop":"roleName","label":"角色名称"}}),_c('el-table-column',{attrs:{"align":"center","prop":"roleCreateUserId","label":"创建人主键"}}),_c('el-table-column',{attrs:{"align":"center","prop":"roleCreateUserTrueName","label":"创建人名称"}}),_c('el-table-column',{attrs:{"align":"center","label":"创建时间","prop":"roleCreateTime"}}),_c('el-table-column',{attrs:{"align":"center","label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var row = ref.row;
return [(
            _vm.$store.state.powerList.indexOf(
              'system:permission:role:update'
            ) !== -1
          )?_c('el-button',{attrs:{"size":"mini","type":"primary"},on:{"click":function($event){return _vm.onEdit(row)}}},[_vm._v("编辑")]):_vm._e(),(
            _vm.$store.state.powerList.indexOf(
              'system:permission:role:delete'
            ) !== -1
          )?_c('el-button',{attrs:{"size":"mini","type":"primary"},on:{"click":function($event){return _vm.delRole(row)}}},[_vm._v("删除")]):_vm._e(),(
            _vm.$store.state.powerList.indexOf(
              'system:permission:role:empower'
            ) !== -1
          )?_c('el-button',{attrs:{"size":"mini","type":"primary"},on:{"click":function($event){return _vm.onAddPowerToRole(row)}}},[_vm._v("赋权")]):_vm._e()]}}])})],1),(
      _vm.$store.state.powerList.indexOf('system:permission:role:delete') !== -1
    )?_c('el-button',{staticClass:"btn",attrs:{"type":"primary"},on:{"click":_vm.delAllRole}},[_vm._v("删除选中")]):_vm._e(),_c('el-pagination',{attrs:{"current-page":this.currentPage,"page-sizes":[5, 10, 15, 20],"page-size":100,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.pagination.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}}),_c('el-dialog',{attrs:{"title":"新增角色","visible":_vm.addRoleDialogVisible,"width":"30%"},on:{"update:visible":function($event){_vm.addRoleDialogVisible=$event}}},[_c('el-form',{ref:"form",attrs:{"label-width":"80px"}},[_c('el-form-item',{attrs:{"label":"角色名称"}},[_c('el-input',{attrs:{"placeholder":"请输入角色名称"},model:{value:(_vm.addRoleName),callback:function ($$v) {_vm.addRoleName=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"addRoleName"}})],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.addRoleDialogVisible = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.onAddRoleOk}},[_vm._v("确 定")])],1)],1),_c('el-dialog',{attrs:{"title":"编辑角色","visible":_vm.editRoleDialogVisible,"width":"30%"},on:{"update:visible":function($event){_vm.editRoleDialogVisible=$event}}},[_c('el-form',{ref:"form",attrs:{"label-width":"80px"}},[_c('el-form-item',{attrs:{"label":"角色名称"}},[_c('el-input',{attrs:{"placeholder":"请输入角色名称"},model:{value:(_vm.editRole.roleName),callback:function ($$v) {_vm.$set(_vm.editRole, "roleName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"editRole.roleName"}})],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.editRoleDialogVisible = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.onEditRoleOk}},[_vm._v("确 定")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }