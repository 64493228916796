<template>
  <div class="rolelist">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>角色列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search">
      <span>名称:</span>
      <el-input placeholder="请输入栏目名称" v-model.trim="roleName"></el-input>
      <el-button type="primary" @click="getRoleList()">搜索</el-button>
      <el-button
        type="primary"
        @click="addRoleDialogVisible = true"
        v-if="
          $store.state.powerList.indexOf('system:permission:role:insert') !== -1
        "
        >添加</el-button
      ><el-button type="primary" @click="$router.go(-1)">返回</el-button>
    </div>
    <el-table
      @selection-change="handleSelectionChange"
      style="width: 100%"
      border
      :data="tableData"
    >
      <el-table-column
        align="center"
        type="selection"
        width="55"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="roleId"
        label="角色主键"
        width="80"
      ></el-table-column>
      <el-table-column align="center" prop="roleName" label="角色名称">
      </el-table-column>
      <el-table-column
        align="center"
        prop="roleCreateUserId"
        label="创建人主键"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="roleCreateUserTrueName"
        label="创建人名称"
      >
      </el-table-column>
      <el-table-column align="center" label="创建时间" prop="roleCreateTime">
      </el-table-column>
      <el-table-column align="center" label="操作">
        <template slot-scope="{ row }">
          <el-button
            size="mini"
            type="primary"
            @click="onEdit(row)"
            v-if="
              $store.state.powerList.indexOf(
                'system:permission:role:update'
              ) !== -1
            "
            >编辑</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="delRole(row)"
            v-if="
              $store.state.powerList.indexOf(
                'system:permission:role:delete'
              ) !== -1
            "
            >删除</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="onAddPowerToRole(row)"
            v-if="
              $store.state.powerList.indexOf(
                'system:permission:role:empower'
              ) !== -1
            "
            >赋权</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-button
      type="primary"
      class="btn"
      @click="delAllRole"
      v-if="
        $store.state.powerList.indexOf('system:permission:role:delete') !== -1
      "
      >删除选中</el-button
    >

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="this.currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="100"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
    >
    </el-pagination>
    <el-dialog
      title="新增角色"
      :visible.sync="addRoleDialogVisible"
      width="30%"
    >
      <el-form ref="form" label-width="80px">
        <el-form-item label="角色名称">
          <el-input
            placeholder="请输入角色名称"
            v-model.trim="addRoleName"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addRoleDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onAddRoleOk">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="编辑角色"
      :visible.sync="editRoleDialogVisible"
      width="30%"
    >
      <el-form ref="form" label-width="80px">
        <el-form-item label="角色名称">
          <el-input
            placeholder="请输入角色名称"
            v-model.trim="editRole.roleName"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editRoleDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onEditRoleOk">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { roleList, addRole, editRole, delRole } from "../../api/role";
import { powerList } from "../../api/power";
export default {
  name: "RoleList",
  data() {
    return {
      addRoleName: "",
      roleName: "",
      currentPage: 1,
      pageSize: 5,
      tableData: [],
      addRoleDialogVisible: false,
      editRoleDialogVisible: false,
      editRole: {},
      delRoleList: [],
      delBtnShow: false,
      pagination: {},
      powerList: [],
      addPowerToRoleList: {
        manageRoleId: "",
        managePowerId: [],
        manageColumnId: [],
      },
    };
  },
  created() {
    this.getRoleList();
    this.getPowerList();
  },
  methods: {
    async getPowerList() {
      const { data } = await powerList({
        currentPage: 1,
        pageSize: 1000,
      });
      data.list.map((item) => {
        item.label = item.powerDescribe;
        item.value = item.powerId;
      });
    },
    async getRoleList() {
      const { data } = await roleList({
        roleName: this.roleName,
        currentPage: this.currentPage,
        pageSize: this.pageSize,
      });
      this.pagination = data.pagination;
      this.tableData = data.list;
    },
    async onAddRoleOk() {
      if (this.addRoleName == "") {
        this.$message({
          message: "请输入角色名称!",
          type: "warning",
        });
        return;
      }
      const { data } = await addRole({
        roleName: this.addRoleName,
      });
      if (data.code === 0) {
        this.$message({
          message: "添加角色成功",
          type: "success",
        });
        this.addRoleName = "";
      } else {
        this.$message.error("添加失败请稍后重试");
      }
      this.getRoleList();
      this.addRoleDialogVisible = false;
    },
    onEdit(row) {
      this.editRole = row;
      this.editRoleDialogVisible = true;
    },
    async onEditRoleOk() {
      if (this.editRole.roleName == "") {
        this.$message({
          message: "请输入角色名称!",
          type: "warning",
        });
        return;
      }
      const { data } = await editRole({
        roleId: this.editRole.roleId,
        roleName: this.editRole.roleName,
      });
      console.log(data);
      if (data.code === 0) {
        this.$message({
          message: "编辑成功",
          type: "success",
        });
      } else {
        this.$message.error("编辑失败请稍后重试");
      }
      this.editRoleDialogVisible = false;
    },
    delRole(row) {
      this.delRoleList.push(row.roleId);
      this.$confirm("是否删除这个角色, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await delRole({
            roleIdListStr: this.delRoleList,
          });
          this.delRoleList = [];
          this.getRoleList();
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    onAddPowerToRole(row) {
      console.log(row, "row");
      this.$router.push(`/roleEmpowerment/${row.roleId}`);
    },
    handleSelectionChange(val) {
      var newList = [];
      val.map((item) => {
        return newList.push(item.roleId);
      });
      console.log(newList);
      if (newList.length !== []) {
        this.delBtnShow = true;
      }
      this.delRoleList = newList;
    },
    delAllRole() {
      this.$confirm("是否删除所有选中项?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await delRole({
            roleIdListStr: this.delRoleList,
          });
          this.delRoleList = [];
          this.getRoleList();
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleSizeChange(sixe) {
      this.pageSize = sixe;
      this.getRoleList();
    },
    handleCurrentChange(Current) {
      this.currentPage = Current;
      this.getRoleList();
    },
  },
};
</script>
<style lang="less" scoped>
.rolelist {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .search {
    margin-top: 50px;
    .el-input {
      width: 500px;
      margin: 0 40px 0 20px;
    }
  }
  .el-table {
    margin-top: 50px;
  }
  .btn {
    margin-top: 30px;
  }
  .el-pagination {
    margin-top: 50px;
    float: right;
  }
}
</style>